<template>
  <div class="search-faq-items">
    <esmp-link
      v-for="item in items"
      :key="`item_${item.id}`"
      class="search-faq-item"
      :to="`/faq/article/${item.id}`"
    >
      <h4 class="search-faq-item__item">
        {{ item.title }}
      </h4>

      <p v-if="item.categoryTitle " class="search-faq-item__caption">
        {{ item.categoryTitle }}
      </p>
    </esmp-link>
  </div>
</template>

<script>
export default {
  name: 'SearchFaqItem',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.search-faq-items {
  background: $color-white;
  border-radius: $base-border-radius * 2;
  padding: 16px;
}

.search-faq-item {
  display: block;
  padding: 16px;
  border-radius: 12px;
  transition: background $base-animation-time $base-animation-function;

  &__title {
    margin-bottom: 8px;
  }

  &__caption {
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
  }

  &:hover {
    background: $color-black-op-05;
  }
}
</style>
